import { render, staticRenderFns } from "./LocaleSelectNotLocalized.vue?vue&type=template&id=70e96bcc&scoped=true"
import script from "./LocaleSelectNotLocalized.vue?vue&type=script&lang=js"
export * from "./LocaleSelectNotLocalized.vue?vue&type=script&lang=js"
import style0 from "./LocaleSelectNotLocalized.vue?vue&type=style&index=0&id=70e96bcc&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70e96bcc",
  null
  
)

export default component.exports