<template>
    <footer class="auth-footer">
        <p>© GGSKIN. {{ $t('home.footer.rights') }}.</p>

        <div class="auth-footer__recaptcha" v-html="$t('recaptcha')" />

        <!-- <div class="auth-footer__nav">
            <div class="auth-footer__nav-left">
                <a href="#">{{ $t('home.footer.nav.contacts') }}</a>

                <a href="#">{{ $t('home.footer.nav.help') }}</a>
            </div>

            <div class="auth-footer__nav-right">
                <a href="#">{{ $t('home.footer.nav.terms_of_use') }}</a>

                <a href="#">{{ $t('home.footer.nav.privacy_policy') }}</a>
            </div>
        </div> -->
    </footer>
</template>

<script>
    export default {
        name: 'AuthFooter',
    };
</script>

<style lang="scss" scoped>
    .auth-footer {
        display: flex;
        flex-direction: column-reverse;
        flex-shrink: 0;
        grid-gap: 2.4rem;
        width: 100%;
        font-family: 'Gotham Book', sans-serif;
        font-size: 1.5rem;
        font-weight: 325;
        color: #b0b8d2;
    }

    .auth-footer__nav {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        grid-column-gap: 2rem;
        grid-row-gap: 1rem;
        max-width: 35rem;

        > div {
            display: flex;
            flex-direction: column;
            grid-gap: 1rem;
        }

        a {
            color: #828aa4;
            transition: color 0.3s;

            &:hover {
                color: #ffffff;
            }
        }
    }

    .auth-footer__recaptcha {
        max-width: 40rem;
        line-height: 1.4;
    }

    @media screen and (min-width: 23.25em) {
        .auth-footer__nav > div:last-of-type a {
            text-align: right;
        }
    }

    @media screen and (min-width: 48em) {
        .auth-footer {
            align-items: center;
        }

        .auth-footer__nav {
            max-width: unset;

            > div {
                flex-direction: row;
                grid-gap: 3rem;
            }
        }

        .auth-footer__recaptcha {
            max-width: unset;
        }
    }

    @media screen and (min-width: 75em) {
        .auth-footer {
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        .auth-footer__recaptcha {
            order: 1;
            width: 100%;
        }
    }
</style>
