<template>
    <locale-select class="locale-select_not-localized" :flags="false" :localize="false" />
</template>

<script>
    import LocaleSelect from '@/components/inputs/LocaleSelect';

    export default {
        name: 'LocaleSelectNotLocalized',
        components: {
            LocaleSelect,
        },
    };
</script>

<style lang="scss" scoped>
    .locale-select_not-localized::v-deep {
        .select__value {
            height: 3.6rem;
            padding: 0 1rem;
            background-color: #202738;

            p {
                color: #a9aebd;
            }

            svg {
                margin-left: 0.8rem;
                fill: #a9aebd;
            }

            &:active,
            &:focus,
            &:hover {
                background-color: #262c41;

                p {
                    color: #ccd1de;
                }

                svg {
                    fill: #ccd1de;
                }
            }

            &_opened {
                border-bottom-left-radius: 0.8rem;
                border-bottom-right-radius: 0.8rem;
            }
        }

        .select__options {
            margin-top: 0.5rem;
            border-top-left-radius: 0.8rem;
            border-top-right-radius: 0.8rem;
            background-color: #202738;
        }

        .select__option {
            height: auto;
            padding: 0.5rem 1rem;
            cursor: pointer;

            p {
                color: #a9aebd;
            }

            &:first-of-type {
                padding-top: 1.15rem;
            }

            &:last-of-type {
                padding-bottom: 1.15rem;
            }

            &:hover {
                background-color: #262c41;
                opacity: 1;

                p {
                    color: #ccd1de;
                }
            }
        }
    }
</style>
