<template>
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M8.9948 3.9948C10.6545 2.33507 13.3455 2.33507 15.0052 3.9948C16.6649 5.65453 16.6649 8.34548 15.0052 10.0052C13.3455 11.6649 10.6545 11.6649 8.9948 10.0052C7.33507 8.34548 7.33507 5.65453 8.9948 3.9948Z"
        />

        <path
            d="M12 12.7578C9.86712 12.7578 7.7321 13.2735 6.10466 14.2209C4.48397 15.1644 3.25 16.6181 3.25 18.4998V19.4998C3.25 20.466 4.03379 21.2498 5 21.2498H19C19.9662 21.2498 20.75 20.466 20.75 19.4998V18.4998C20.75 16.6181 19.516 15.1644 17.8953 14.2209C16.2679 13.2735 14.1329 12.7578 12 12.7578Z"
        />
    </svg>
</template>

<script>
    export default {
        name: 'UserIcon',
    };
</script>
