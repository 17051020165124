<template>
    <header class="auth-header">
        <div class="auth-header__left">
            <router-link to="/" class="auth-header__logo-link">
                <img src="~@/assets/images/logo.svg" alt="logo" />
            </router-link>

            <locale-select-not-localized class="auth-header__locale-select" />
        </div>

        <div v-if="withMenu" class="auth-header__right">
            <ul>
                <li @click="$emit('scrollTo', 'why')">{{ $t('home.header.nav.why') }}</li>

                <li @click="$emit('scrollTo', 'partners')">{{ $t('home.header.nav.partners') }}</li>

                <li>
                    <a href="mailto:support@gg.skin">{{ $t('home.header.nav.contacts') }}</a>
                </li>
            </ul>

            <router-link :to="{ name: 'auth-login' }">
                <icon-button theme="info">
                    <user-icon />
                </icon-button>
            </router-link>

            <router-link :to="{ name: 'auth-login' }">
                <text-button theme="info" full>
                    {{ $t(isAuthenticated ? 'home.header.account' : 'home.header.authorization') }}
                    <user-icon />
                </text-button>
            </router-link>
        </div>
    </header>
</template>

<script>
    import { mapGetters } from 'vuex';
    import CartIcon from '@/components/icons/CartIcon';
    import IconButton from '@/components/buttons/IconButton';
    import LocaleSelectNotLocalized from '@/components/inputs/LocaleSelectNotLocalized';
    import Logo from '@/components/Logo';
    import TextButton from '@/components/buttons/TextButton';
    import UserIcon from '@/components/icons/UserIcon';

    export default {
        name: 'AuthHeader',
        components: {
            CartIcon,
            IconButton,
            LocaleSelectNotLocalized,
            Logo,
            TextButton,
            UserIcon,
        },
        props: {
            withMenu: {
                type: Boolean,
                default: true,
            },
        },
        computed: {
            ...mapGetters('auth', ['isAuthenticated']),
        },
    };
</script>

<style lang="scss" scoped>
    .auth-header,
    .auth-header__left {
        display: flex;
        align-items: center;
        grid-gap: 1rem;
    }

    .auth-header {
        position: inherit;
        flex-shrink: 0;
        flex-wrap: wrap;
        justify-content: space-between;
        grid-column-gap: 2rem;
        width: 100%;
        max-width: 128rem;
        margin: 0 auto;
        padding: 1.5rem;
        background-color: #0e1527;
    }

    .auth-header__right {
        ul,
        > a:last-of-type {
            display: none;
        }
    }

    .auth-header__logo-link:hover {
        opacity: 0.75;
    }

    @media screen and (min-width: 64em) {
        .auth-header {
            padding: 4rem;
            background-color: unset;
        }

        .auth-header__left {
            grid-gap: 2.4rem;
        }

        .auth-header__right {
            display: flex;
            align-items: center;
            grid-gap: 4rem;

            a {
                &:first-of-type {
                    display: none;
                }

                &:last-of-type {
                    display: block;
                }
            }

            ul {
                grid-gap: 4rem;
                display: flex;

                li,
                li a {
                    color: #828aa4;
                }

                li {
                    font-family: 'Gotham Book', sans-serif;
                    font-size: 1.4rem;
                    transition: color 0.3s;
                    cursor: pointer;

                    &:hover,
                    &:hover a {
                        color: #ffffff;
                    }
                }
            }
        }
    }

    @media screen and (min-width: 120em) {
        .auth-header__right ul li {
            font-size: 1.6rem;
        }
    }
</style>
