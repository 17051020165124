<template>
    <svg viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M5.00065 5C4.08018 5 3.33398 5.74619 3.33398 6.66667C3.33398 7.58714 4.08018 8.33333 5.00065 8.33333H6.81196L7.12871 11.8176L8.04483 21.8949C8.29142 24.6074 10.6637 26.6236 13.3805 26.4295L30.7707 25.1873C33.1184 25.0196 35.0313 23.2372 35.3642 20.9072L36.6506 11.9024C36.7189 11.4241 36.5763 10.9397 36.2597 10.5747C35.9432 10.2097 35.4838 10 35.0007 10H10.3106L9.99381 6.51577C9.91576 5.65731 9.19599 5 8.33398 5H5.00065ZM16.6673 32.5C16.6673 33.8807 15.548 35 14.1673 35C12.7866 35 11.6673 33.8807 11.6673 32.5C11.6673 31.1193 12.7866 30 14.1673 30C15.548 30 16.6673 31.1193 16.6673 32.5ZM29.1673 35C30.548 35 31.6673 33.8807 31.6673 32.5C31.6673 31.1193 30.548 30 29.1673 30C27.7866 30 26.6673 31.1193 26.6673 32.5C26.6673 33.8807 27.7866 35 29.1673 35Z"
        />
    </svg>
</template>

<script>
    export default {
        name: 'CartIcon',
    };
</script>
